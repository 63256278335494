<!--
 * @Description: 确认订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-23 23:46:39
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-29 13:10:21
 -->
<template>
  <div class="confirmOrder">
    <!-- 头部 -->
    <div class="confirmOrder-header">
      <div class="header-content">
        <p>
          <i class="el-icon-s-order"></i>
        </p>
        <p>确认订单</p>
        <router-link to></router-link>
      </div>
    </div>
    <!-- 头部END -->

    <!-- 主要内容容器 -->
    <div class="content">
      <!-- 选择地址 -->
      <div class="section-address">
        <p class="title">收货信息</p>
        <el-divider></el-divider>
        <div class="address-body">
          <el-form :model="addressForm" :rules="addressRules" ref="addressRuleForm" label-width="100px">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="姓名" prop="username">
                  <el-input v-model="addressForm.username"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="单位名称" prop="unit">
                  <el-input v-model="addressForm.unit"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收货地址" prop="address">
                  <el-input v-model="addressForm.address"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话" prop="mobile">
                  <el-input v-model="addressForm.mobile"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="接收邮箱" prop="email">
                  <el-input v-model="addressForm.email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- 选择地址END -->
      <div class="section-address">
        <p class="title">发票信息</p>
        <el-divider></el-divider>
        <div class="address-body">
          <el-form ref="form" :model="addressForm" label-width="100px">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="开票抬头">
                  <el-input v-model="addressForm.invoice_header"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="税务登记号">
                  <el-input v-model="addressForm.registration_no"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="注册地址">
                  <el-input v-model="addressForm.regist_address"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开户银行">
                  <el-input v-model="addressForm.bank"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="开户银行账号">
                  <el-input v-model="addressForm.bank_account"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电话">
                  <el-input v-model="addressForm.bank_phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- 商品及优惠券 -->
      <div class="section-goods">
        <p class="title">商品</p>
        <div class="goods-list">
          <ul>
            <li v-for="item in getCheckGoods" :key="item.id">
              <!-- <img :src="$target + item.productImg" /> -->
              <span class="pro-name" style="font-size: 18px;"><span
                  style=" display: inline-block; width: 100px; color: #1955B8; font-size: 18px;">{{ item.spec_name
                  }}</span>{{ item.content }}</span>
              <span class="pro-price">{{ item.price }}元 x {{ item.num }}</span>
              <span class="pro-status"></span>
              <span class="pro-total">{{ item.price * item.num }}元</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 商品及优惠券END -->

      <!-- 配送方式 -->
      <!-- <div class="section-shipment">
        <p class="title">配送方式</p>
        <p class="shipment">包邮</p>
      </div> -->
      <!-- 配送方式END -->

      <!-- 发票 -->
      <!-- <div class="section-invoice">
        <p class="title">发票</p>
        <p class="invoice">电子发票</p>
        <p class="invoice">个人</p>
        <p class="invoice">商品明细</p>
      </div> -->
      <!-- 发票END -->

      <!-- 结算列表 -->
      <div class="section-count">
        <div class="money-box">
          <ul>
            <li>
              <span class="title">商品件数：</span>
              <span class="value">{{ getCheckNum }}件</span>
            </li>
            <li>
              <span class="title">商品总价：</span>
              <span class="value">{{ getTotalPrice }}元</span>
            </li>
            <!-- <li>
              <span class="title">活动优惠：</span>
              <span class="value">-0元</span>
            </li>
            <li>
              <span class="title">优惠券抵扣：</span>
              <span class="value">-0元</span>
            </li>
            <li>
              <span class="title">运费：</span>
              <span class="value">0元</span>
            </li> -->
            <li class="total">
              <span class="title">应付总额：</span>
              <span class="value">
                <span class="total-price">{{ getTotalPrice }}</span>元
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 结算列表END -->

      <!-- 结算导航 -->
      <div class="section-bar">
        <div class="btn">
          <router-link to="/shoppingCart" class="btn-base btn-return">返回购物车</router-link>
          <a href="javascript:void(0);" @click="addOrder" class="btn-base btn-primary">提交订单</a>
        </div>
      </div>
      <!-- 结算导航END -->
    </div>
    <!-- 主要内容容器END -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { orderAddApi, invoiceApi } from "../api/index"
export default {
  name: "",
  data() {
    return {
      // 虚拟数据
      confirmAddress: 1, // 选择的地址id
      // 地址列表
      addressForm: {
        id: 1,
        username: "",
        mobile: "",
        unit: "",
        email: "",
        address: "",
        invoice_header: '',
        registration_no: '',
        regist_address: '',
        bank: '',
        bank_account: '',
        bank_phone: '',
      },
      invoiceForm: {
        invoice_header: '',
        registration_no: '',
        regist_address: '',
        bank: '',
        bank_account: '',
        bank_phone: '',
      },
      addressRules: {
        username: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        unit: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入收货地址', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
        ],
      }
    };
  },
  created() {
    // 如果没有勾选购物车商品直接进入确认订单页面,提示信息并返回购物车
    // if (this.getCheckNum < 1) {
    //   this.notifyError("请勾选商品后再结算");
    //   this.$router.push({ path: "/shoppingCart" });
    // }
  },
  activated() {
    // this.getInvoice()
    this.selectfpInfo()
  },
  computed: {
    // 结算的商品数量; 结算商品总计; 结算商品信息
    ...mapGetters(["getCheckNum", "getTotalPrice", "getCheckGoods"])
  },
  methods: {
    ...mapActions(["deleteShoppingCart"]),
    getInvoice() {
      invoiceApi().then(res => {
        if (res.data != null) {
          this.invoiceForm = res.data
        }
      })
    },
    // 查询发票信息
    selectfpInfo() {
      invoiceApi().then(res => {
        this.addressForm = res.data
      })
    },
    // 发送邮件
    // sendEmail(){
    //   sendEmailApi().then(res => {
    //   })
    // },
    addOrder() {
      console.log(this.addressForm, this.invoiceForm);

      let data = {
        ...this.addressForm,
        // ...this.invoiceForm
      }
      console.log(data);
      orderAddApi(data).then(res => {
        console.log('--------------aaa>', res);
        this.$router.push({ path: "/userInfo" });
      })
        .catch(err => {
          this.notifyError(err.data.msg);
          console.log('1231321223');
          return Promise.reject(err);
        });
      // this.$axios
      //   .post("/api/user/order/addOrder", {
      //     user_id: this.$store.getters.getUser.user_id,
      //     products: this.getCheckGoods
      //   })
      //   .then(res => {
      //     let products = this.getCheckGoods;
      //     switch (res.data.code) {
      //       // “001”代表结算成功
      //       case "001":
      //         for (let i = 0; i < products.length; i++) {
      //           const temp = products[i];
      //           // 删除已经结算的购物车商品
      //           this.deleteShoppingCart(temp.id);
      //         }
      //         // 提示结算结果
      //         this.notifySucceed(res.data.msg);
      //         // 跳转我的订单页面
      //         this.$router.push({ path: "/order" });
      //         break;
      //       default:
      //         // 提示失败信息
      //         this.notifyError(res.data.msg);
      //     }
      //   })
      //   .catch(err => {
      //     return Promise.reject(err);
      //   });
    }
  }
};
</script>
<style scoped>
.confirmOrder {
  background-color: #f5f5f5;
  padding-bottom: 20px;
}

/* 头部CSS */
.confirmOrder .confirmOrder-header {
  background-color: #fff;
  border-bottom: 2px solid #CE3720;
  margin-bottom: 20px;
}

.confirmOrder .confirmOrder-header .header-content {
  width: 1225px;
  margin: 0 auto;
  height: 80px;
}

.confirmOrder .confirmOrder-header .header-content p {
  float: left;
  font-size: 28px;
  line-height: 80px;
  color: #424242;
  margin-right: 20px;
}

.confirmOrder .confirmOrder-header .header-content p i {
  font-size: 45px;
  color: #CE3720;
  line-height: 80px;
}

/* 头部CSS END */

/* 主要内容容器CSS */
.confirmOrder .content {
  width: 1225px;
  margin: 0 auto;
  padding: 48px 0 0;
  background-color: #fff;
}

/* 选择地址CSS */
.confirmOrder .content .section-address {
  margin: 0 48px;
  overflow: hidden;

}

.confirmOrder .content .section-address .title {
  color: #333;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
}

.confirmOrder .content .address-body li {
  float: left;
  color: #333;
  width: 220px;
  height: 178px;
  border: 1px solid #e0e0e0;
  padding: 15px 24px 0;
  margin-right: 17px;
  margin-bottom: 24px;
}

.confirmOrder .content .address-body .in-section {
  border: 1px solid #CE3720;
}

.confirmOrder .content .address-body li h2 {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  margin-bottom: 10px;
}

.confirmOrder .content .address-body li p {
  font-size: 14px;
  color: #757575;
}

.confirmOrder .content .address-body li .address {
  padding: 10px 0;
  max-width: 180px;
  max-height: 88px;
  line-height: 22px;
  overflow: hidden;
}

.confirmOrder .content .address-body .add-address {
  text-align: center;
  line-height: 30px;
}

.confirmOrder .content .address-body .add-address i {
  font-size: 30px;
  padding-top: 50px;
  text-align: center;
}

/* 选择地址CSS END */

/* 商品及优惠券CSS */
.confirmOrder .content .section-goods {
  margin: 0 48px;
}

.confirmOrder .content .section-goods p.title {
  color: #333;
  font-size: 18px;
  line-height: 40px;
}

.confirmOrder .content .section-goods .goods-list {
  padding: 5px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.confirmOrder .content .section-goods .goods-list li {
  padding: 10px 0;
  color: #424242;
  overflow: hidden;
}

.confirmOrder .content .section-goods .goods-list li img {
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.confirmOrder .content .section-goods .goods-list li .pro-name {
  float: left;
  width: 650px;
  font-size: 22px;
  line-height: 30px;
}

.confirmOrder .content .section-goods .goods-list li .pro-price {
  float: left;
  width: 150px;
  text-align: center;
  font-size: 22px;
  line-height: 30px;
}

.confirmOrder .content .section-goods .goods-list li .pro-status {
  float: left;
  width: 99px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

.confirmOrder .content .section-goods .goods-list li .pro-total {
  float: left;
  width: 190px;
  text-align: center;
  color: #CE3720;
  line-height: 30px;
  font-size: 22px;
}

/* 商品及优惠券CSS END */

/* 配送方式CSS */
.confirmOrder .content .section-shipment {
  margin: 0 48px;
  padding: 25px 0;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}

.confirmOrder .content .section-shipment .title {
  float: left;
  width: 150px;
  color: #333;
  font-size: 18px;
  line-height: 38px;
}

.confirmOrder .content .section-shipment .shipment {
  float: left;
  line-height: 38px;
  font-size: 14px;
  color: #CE3720;
}

/* 配送方式CSS END */

/* 发票CSS */
.confirmOrder .content .section-invoice {
  margin: 0 48px;
  padding: 25px 0;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}

.confirmOrder .content .section-invoice .title {
  float: left;
  width: 150px;
  color: #333;
  font-size: 18px;
  line-height: 38px;
}

.confirmOrder .content .section-invoice .invoice {
  float: left;
  line-height: 38px;
  font-size: 14px;
  margin-right: 20px;
  color: #CE3720;
}

/* 发票CSS END */

/* 结算列表CSS */
.confirmOrder .content .section-count {
  margin: 0 48px;
  padding: 20px 0;
  font-size: 21px;
  overflow: hidden;
}

.confirmOrder .content .section-count .money-box {
  float: right;
  text-align: right;
}

.confirmOrder .content .section-count .money-box .title {
  float: left;
  width: 126px;
  height: 30px;
  display: block;
  line-height: 30px;
  color: #757575;
}

.confirmOrder .content .section-count .money-box .value {
  float: left;
  min-width: 105px;
  height: 30px;
  display: block;
  line-height: 30px;
  color: #CE3720;
}

.confirmOrder .content .section-count .money-box .total .title {
  padding-top: 15px;
}

.confirmOrder .content .section-count .money-box .total .value {
  padding-top: 10px;
}

.confirmOrder .content .section-count .money-box .total-price {
  font-size: 30px;
}

/* 结算列表CSS END */

/* 结算导航CSS */
.confirmOrder .content .section-bar {
  padding: 20px 48px;
  border-top: 2px solid #f5f5f5;
  overflow: hidden;
}

.confirmOrder .content .section-bar .btn {
  float: right;
}

.confirmOrder .content .section-bar .btn .btn-base {
  float: left;
  margin-left: 30px;
  width: 158px;
  height: 38px;
  border: 1px solid #b0b0b0;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
}

.confirmOrder .content .section-bar .btn .btn-return {
  color: rgba(0, 0, 0, 0.27);
  border-color: rgba(0, 0, 0, 0.27);
}

.confirmOrder .content .section-bar .btn .btn-primary {
  background: #CE3720;
  border-color: #CE3720;
  color: #fff;
}

/* 结算导航CSS */

/* 主要内容容器CSS END */
.grid-content {
  display: flex;
}
</style>